import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from '../../shared/common';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public isLoggedInStatus: boolean;
  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    })
  };
  constructor(private http: HttpClient, private global: GlobalService) {  }

  reinit(): void {
    this.httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      })
    };
  }




  login(username, password): Observable<any> {
    return this.http.post<any>(`${this.global.serverURL}/auth/generate-token`,
      { username, password }).pipe(map((res: any) => {
        sessionStorage.setItem('token', res.token)
        return res;
      }))
  }

  registerUser(form): Observable<any> {
    return this.http.post<any>(`${this.global.serverURL}/user/add_user`, form, this.httpOptions);
  }

  changePassword(form): Observable<any> {
    return this.http.post<any>(`${this.global.serverURL}/user/change_password`, form, this.httpOptions)
  }

  getVerificationCodeForRegister(form): Observable<any> {
    return this.http.post<any>(`${this.global.serverURL}/user/get_verification_code`, form, this.httpOptions);
  }

  async isLoggedIn() {
    return await this.checkUserLoginStat().then(res => {
      this.isLoggedInStatus = true;
      return true;
    }, error => {
      this.isLoggedInStatus = false;
      return false;
    })
  }

  async checkUserLoginStat() {
    return await this.http.get<any>(`${this.global.serverURL}/user/retrieve_current_user_info`, this.httpOptions)
      .pipe(map((res: any) => {
        return true;
      }, error => {
        return false;

      })).toPromise();
  }
  getCurrentUser(): Observable<any> {
    this.reinit();
    return this.http.get<any>(`${this.global.serverURL}/user/retrieve_current_user_info`, this.httpOptions)
      .pipe(map((res: any) => {
        sessionStorage.setItem('check', res.content.hasAdminRole);
        sessionStorage.setItem('email', res.content.email);
        return res.content;
      }))
  }

  async getCurrentUserInfo() {
    this.reinit();
    return await this.http.get<any>(`${this.global.serverURL}/user/retrieve_current_user_info`, this.httpOptions)
      .pipe(map((res: any) => {
        sessionStorage.setItem('check', res.content.hasAdminRole);
        sessionStorage.setItem('email', res.content.email);
        sessionStorage.setItem('name', res.content.param1);
        return res.content;
      })).toPromise();
  }

}
